import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FormatShortDateValueConverter } from 'resources/value-converters/format-short-date';
import { MyHttpApi, DiscountGroupByIdResult, OrderGiftCardOrderRequest, Product, ProductCategory, ProductSubCategory, PublicStoreByIdResponse, StoreProductCatalog, deepClone } from 'utils/api';
import { Notify } from 'utils/notify';
import { TranslationUtil } from 'utils/translation-util';

interface SellableProductCategory {
  productCategory: ProductCategory;
  productSubCategoryList: SellableProductSubCategory[];
}

interface SellableProductSubCategory {
  productSubCategory?: ProductSubCategory;
  productList: SellableProduct[];
}

interface UIProduct extends Product {
  price: number;
}

interface SellableProduct {
  product: UIProduct;
  inventory: number | undefined;
  productCategory: ProductCategory;
  productSubCategory?: ProductSubCategory;
  storeProductCatalog: StoreProductCatalog;
  idx: number;
}

interface AmountByIdx {
  amount: number,
  orderError?: string,
  productId: number,
  price?: number;
  show: boolean;
}

@autoinject
export class GiftShopIndex {
  private amountByIdx: AmountByIdx[] = [];
  private store?: PublicStoreByIdResponse;
  private storePoller?: NodeJS.Timer;
  private deliveryDate = new Date();

  private sellableProductList: SellableProduct[] = [];

  private discountGroupList: DiscountGroupByIdResult[] = [];

  private confirmModal = false;
  private searchModal = false;

  private moveAnimation = "initial";
  private sendOrderWaiting = false;

  /* user info */
  private firstName?: string;
  private lastName?: string;
  private email?: string;
  private phone?: string;
  private discountCode?: string;
  private privacyPolicy = "";

  @observable
  private privacyPolicyAccepted = false;
  
  private showPrivacyPolicyModal = false;
  private showTermsAndConditionsModal = false;
  private privacyPolicyDate = "11.12.2023";
  constructor(private i18n: I18N, private client: MyHttpApi, private router: Router, private notify: Notify, private translationUtil: TranslationUtil) {
  }

  attached() {
    document.addEventListener("scroll", this.scrollTrigger.bind(this));
    this.privacyPolicyAccepted = localStorage.getItem(`policyAndTermsAccepted-${this.privacyPolicyDate}`) == "1";
  }

  detached() {
    document.removeEventListener("scroll", this.scrollTrigger.bind(this));
  }

  privacyPolicyAcceptedChanged(newValue: boolean, oldValue: boolean) {
    if (oldValue != undefined) {
      localStorage.setItem(`policyAndTermsAccepted-${this.privacyPolicyDate}`, newValue ? "1" : "0");
    }
  }

  async openPrivacyPolicy() {
    this.showPrivacyPolicyModal = true;
    this.privacyPolicy = await (await fetch(`/register-fi.html`)).text();
  }

  async openTermsAndConditions() {
    this.showTermsAndConditionsModal = true;
  }

  acceptPolicyAndTerms() {
    this.privacyPolicyAccepted = true;
    localStorage.setItem(`policyAndTermsAccepted-${this.privacyPolicyDate}`, "1");
    this.showPrivacyPolicyModal = false;
    this.showTermsAndConditionsModal = false;
  }

  scrollTrigger(event: Event) {
    if (window.scrollY > 250) {
      this.moveAnimation = "move";
    } else if (this.moveAnimation == "move") {
      this.moveAnimation = "original";
    }
  }

  toggleSearchModal() {
    this.searchModal = !this.searchModal;
  }

  @computedFrom("client.session")
  get fillUserDataObserver() {
    if (this.client.session) {
      this.client.actorSelf()
        .then(self => {
        this.firstName = self.firstName;
        this.lastName = self.lastName;
        this.email = self.email;
        this.phone = self.phone;
      }).catch(_ => { /*no-op*/});
    }
    return "";
  }

  async activate(params: {}) {
    this.notify.loginCallback = () => { /* no action */ };

    this.store = this.notify.store;
    if (!this.store || this.store.store.storeType !== 'GIFT_CARD') {
      this.router.navigateToRoute("/", params);
      return;
    }
    this.deliveryDate = this.store.today;
    this.sellableProductList = this.closed ? [] : this.computeSellableProductList(this.store);

    this.amountByIdx = this.sellableProductList.map(product => ({
      amount: 0,
      productId: product.product.id,
      show: false,
      price: product.product.price,
    }));
    this.startPollStore();
  }

  /**
   * When user inputs a discount code, try to find and apply these discounts.
   */
  async maybeFetchDiscounts(discountCodeFeedback: boolean, emailFeedback: boolean) {
    let tmp: { [key: number]: DiscountGroupByIdResult } = {};
    if (this.store) {
      if (this.email) {
        let discountGroupList = await this.client.publicDiscountsByEmail({ businessUnitId: this.store.businessUnit.id, email: this.email });
        for (let row of discountGroupList) {
          tmp[row.discountGroup.id] = row;
        }
        if (emailFeedback) {
          this.notify.info(discountGroupList.length ? "store.discountGroupsFound" : "store.discountGroupsNotFound");
        } else if(discountGroupList.length) {
          this.notify.info("store.discountGroupsFound");
        }
      }
      if (this.discountCode) {
        let discountGroupList = await this.client.publicDiscountsByCode({ businessUnitId: this.store.businessUnit.id, code: this.discountCode });
        for (let row of discountGroupList) {
          tmp[row.discountGroup.id] = row;
        }
        if (discountCodeFeedback) {
          this.notify.info(discountGroupList.length ? "store.discountGroupsFound" : "store.discountGroupsNotFound");
        }
      }
    }

    this.discountGroupList = Object.values(tmp);
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }

  localizeBrand(string: string | undefined, _language: string) {
    if (!string) {
      return "";
    } else {
      return string.replace(/\{([^}]+)\}/g, (_match, token) => this.i18n.tr(token, {}));
    }
  }

  @computedFrom("store")
  get closed() {
    if (!this.store) {
      return undefined;
    }

    if (this.store.businessUnit.openingDate > this.store.today) {
      // Yksikön asetuksiin on merkattu avaamispäivämäärä on tulevaisuudessa.
      return "order.shopIsClosedButOpensInFuture";
    }

    if (this.store.businessUnit.closingDate && this.store.businessUnit.closingDate < this.store.today) {
      // Yksikön asetuksiin on merkattu sulkemispäivämäärä joka on menneisyydessä.
      return "order.shopIsClosed";
    }

    if (this.store.store.closed && this.store.store.closed >= this.deliveryDate) {
      // Kauppa on suljettu tilapäisesti
      return "order.shopIsClosedTemporarily";
    }

    return undefined;
  }

  createUIProduct(storeProduct: Product, store: PublicStoreByIdResponse) {
    let product: UIProduct = { ...storeProduct, price: 0 };
    let portion = store.productPortionList.find(pp => pp.productId === product.id);
    if (portion) {
      product.price = portion.price;
    }
    return product;
  }

  computeSellableProductList(store: PublicStoreByIdResponse) {
    let spcMap = new Map<number, StoreProductCatalog>();
    for (let spc of store.storeProductCatalogList) {
      spcMap.set(spc.productId, spc);
    }
    let pcMap = MyHttpApi.toHash(store.productCategoryList);
    let pscMap = MyHttpApi.toHash(store.productSubCategoryList);

    const productList = store.productList.map(product => this.createUIProduct(product, store));

    productList.sort((a, b) => {
      let aPC = pcMap[a.productCategoryId] || { weight: 0, name: "" };
      let bPC = pcMap[b.productCategoryId] || { weight: 0, name: "" }
      if (aPC.weight < bPC.weight) {
        return -1;
      } else if (aPC.weight > bPC.weight) {
        return 1;
      } else if (aPC.name < bPC.name) {
        return -1;
      } else if (aPC.name > bPC.name) {
        return 1;
      }

      let aPSC = pscMap[a.productSubCategoryId || 0] || { weight: 0, name: "" };
      let bPSC = pscMap[b.productSubCategoryId || 0] || { weight: 0, name: "" }
      if (aPSC.weight < bPSC.weight) {
        return -1;
      } else if (aPSC.weight > bPSC.weight) {
        return 1;
      } else if (aPSC.name < bPSC.name) {
        return -1;
      } else if (aPSC.name > bPSC.name) {
        return 1;
      } else if (a.weight < b.weight) {
        return -1;
      } else if (a.weight > b.weight) {
        return 1;
      } else {
        if (store.store.productSorting == "NAME") {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }
          return 0;
        } else if (store.store.productSorting == "PRICE") {
          if (a.price && b.price && a.price < b.price) {
            return -1;
          } else if (a.price && b.price && a.price > b.price) {
            return 1;
          }
          return 0;
        }
        return 0;
      }
    });
    let list: SellableProduct[] = [];
    let idx = 0;
    for (let product of productList) {
      /* Confirm that we have PC */
      let productCategory = pcMap[product.productCategoryId];
      if (!productCategory) {
        continue;
      }

      /* And PSC, if defined */
      let productSubCategory = pscMap[product.productSubCategoryId || 0];
      if (product.productSubCategoryId && !productSubCategory) {
        continue;
      }

      let storeProductCatalog = spcMap.get(product.id);
      if (!storeProductCatalog) {
        continue;
      }

      /* Server returns us qty free based on inventory date and known orders since inventory date */
      let inventory: number | undefined = store.theoreticalInventoryStock[product.id];

      list.push({
        product,
        inventory,
        productCategory,
        productSubCategory,
        storeProductCatalog,
        idx: idx++,
      });
    }
    return list;
  }

  deactivate() {
    this.endPollStore();
  }

  startPollStore() {
    if (!this.store) {
      return;
    }
    let id = this.store.store.id;
    this.storePoller = setInterval(async () => {
      this.store = await this.client.publicStoreById({ id });
    }, 60000);
  }

  endPollStore() {
    if (this.storePoller) {
      clearInterval(this.storePoller);
      this.storePoller = undefined;
    }
  }

  @computedFrom("store")
  get logoUrl() {
    if (!this.store) {
      return "";
    }
    return this.client.publicLogoImageUrl({
      id: this.store.businessUnitBrand.businessUnitId, modifyTime: this.store.businessUnitBrand.modifyTime,
    });
  }

  sellableError(product: SellableProduct | undefined, deliveryDate: Date) {
    /* product not known -- we just check product category level checks */
    if (!product) {
      return undefined;
    }

    /* no store product */
    /* not sellable in this period */
    if (product.storeProductCatalog.startDate > deliveryDate) {
      return this.i18n.tr("store.productCanBeSoldInFuture", { startDate: new FormatShortDateValueConverter().toView(product.storeProductCatalog.startDate, "noTime") } as {});
    }
    if (product.storeProductCatalog.endDate && product.storeProductCatalog.endDate < deliveryDate) {
      return this.i18n.tr("store.productNotInSale");
    }
    return undefined;
  }

  @computedFrom("discountGroupList", "sellableProductList", "productCategoryId", "productSubCategoryId", "deliveryDate", "readyBy")
  get sellableProductCategoryList() {
    const store = this.store;
    if (!store) {
      return [];
    }
    const filteredSellableProductList = this.sellableProductList.filter(p => p.storeProductCatalog.startDate <= this.deliveryDate && (!p.storeProductCatalog.endDate || p.storeProductCatalog.endDate >= this.deliveryDate) &&
    !p.storeProductCatalog.discountGroupId || this.discountGroupList.find(dg => dg.discountGroup.id === p.storeProductCatalog.discountGroupId));
    /* group products by their product categories */
    let tmp = new Map<number, Map<number, SellableProduct[]>>();
    for (let product of filteredSellableProductList) {
      if (!tmp.has(product.productCategory.id)) {
        tmp.set(product.productCategory.id, new Map());
      }
      let map = tmp.get(product.productCategory.id)!;
      if (!map.has(product.productSubCategory?.id || 0)) {
        map.set(product.productSubCategory?.id || 0, []);
      }

      let categorizedList = map.get(product.productSubCategory?.id || 0)!;
      categorizedList.push(product);
    }

    /* post-process */
    let list: SellableProductCategory[] = [];
    tmp.forEach((subcategoryMap, productCategoryId) => {
      const productCategory = store.productCategoryList.find(pc => pc.id === productCategoryId);
      if (!productCategory) {
        return;
      }

      const spc: SellableProductCategory = {
        productCategory,
        productSubCategoryList: [],
      };

      subcategoryMap.forEach((productList, productSubCategoryId) => {
        let productSubCategory = store.productSubCategoryList.find(psc => psc.id === productSubCategoryId);
        if (productSubCategoryId && !productSubCategory) {
          return;
        }

        spc.productSubCategoryList.push({
          productSubCategory,
          productList,
        });
      });

      list.push(spc);
    });

    return list;
  }

  @computedFrom("amountByIdx")
  get totalQuantity() {
    let total = 0;
    for (let abi of Object.values(this.amountByIdx)) {
      total += abi.amount;
    }
    return total;
  }

  @computedFrom("amountByIdx")
  get totalValue() {
    let total = 0; /* int */
    for (let idx of Object.keys(this.amountByIdx).map(p => parseInt(p))) {
      let price = this.amountByIdx[idx].amount ? this.calculateTotal(this.sellableProductList[idx].product, this.amountByIdx[idx]) : 0;
      total += Math.round(price * 100);
    }
    return total / 100; /* 2 dec accurate */
  }

  calculateTotal(product: UIProduct, amountByIdx: AmountByIdx) {
    let productTotal = GiftShopIndex.calculatePrice(product, amountByIdx);
    return Math.round(100 * (productTotal || 0)) * (amountByIdx.amount || 1) / 100;
  }

  private static calculatePrice(product: UIProduct, amountByIdx: AmountByIdx) {
    if (product.openPrice) {
      return amountByIdx.price;
    } else {
      return product.price;
    }
  }

  async sendOrder() {
    if (!this.store) {
      return;
    }

    if (!this.client.session) {
      try {
        let session = await this.client.accountRegister({
          storeId: this.store.store.id,
          authenticationType: "EMAIL",
          firstName: this.firstName || '',
          lastName: this.lastName || '',
          email: this.email,
          phone: this.phone,
        });
        this.client.session = session;
      }
      catch (error: any) {
        if (error.message === "server.emailExists") {
          this.notify.loginModal = true;
          this.notify.loginCallback = () => this.sendOrder();
        }
        return;
      }
    }

    let orderRequest: OrderGiftCardOrderRequest = {
      storeId: this.store.store.id,
      products: [],
      language: this.lang == "fi" ? "FI" : "EN",
    };

    for (let idx = 0; idx < this.amountByIdx.length; idx++) {
      const amount = this.amountByIdx[idx];
      const product = this.sellableProductList[idx].product;

      /* no products added */
      if (!amount.amount) {
        continue;
      }

      if (product.openPrice && !amount.price) {
        let el = document.getElementById("idx" + idx);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
        return;
      }

      orderRequest.products.push({
        productId: product.id,
        quantity: amount.amount,
        price: amount.price || 0,
      });
    }
    const orderErrors = await this.client.publicCheckGiftCardOrder(orderRequest);

    for (const orderError of orderErrors) {
      this.amountByIdx.forEach(product => {
        if (product.productId === orderError.productId) {
          product.orderError = this.i18n.tr(orderError.error, { quantity: orderError.availableAmount } as {});
        }
      })
    }
    if (orderErrors.length) {
      return;
    }
    this.sendOrderWaiting = true;
    try {
      const res = await this.client.publicGiftCardOrder(orderRequest);
      let forward = await this.client.publicPaymentHosted({ orderId: res.id });
      this.notify.setForward(forward);
    }
    finally {
      this.sendOrderWaiting = this.notify.hasForward();
    }
  }

  productImage(product: Product) {
    if (!this.store) {
      return undefined;
    }
    if (this.store.productImageExistenceList.indexOf(product.id) !== -1) {
      return this.client.publicProductImageUrl({ id: product.id, modifyTime: product.modifyTime });
    }
    if (this.store.productCategoryImageExistenceList.indexOf(product.productCategoryId) !== -1) {
      let productCategory = this.store.productCategoryList.find(pc => pc.id === product.productCategoryId);
      if (productCategory) {
        return this.client.publicProductCategoryImageUrl({ id: productCategory.id, modifyTime: productCategory.modifyTime });
      }
    }

    return undefined;
  }

  showDetails(idx: number, event?: Event) {
    if (event) {
      let target = event.target;
      if (target instanceof HTMLButtonElement || target instanceof HTMLInputElement || target instanceof HTMLLabelElement || target instanceof HTMLAnchorElement) {
        return true;
      }
    }

    this.amountByIdx[idx].show = !this.amountByIdx[idx].show;
    this.amountByIdx = [...this.amountByIdx];
  }

  refresh() {
    this.amountByIdx = [...this.amountByIdx];
  }

  isProductOutOfStock(sellableProduct: SellableProduct, amount: number) {
    if (!sellableProduct.inventory) {
      return false;
    }
    let totalAmount = this.amountByIdx.reduce((total, p) => total + (p.productId === sellableProduct.product.id ? p.amount : 0), 0);
    return totalAmount >= sellableProduct.inventory;
  }

  addProduct(product: SellableProduct) {
    if (!product.inventory || product.inventory > this.amountByIdx[product.idx].amount) {
      this.amountByIdx[product.idx].amount += 1;
      this.amountByIdx = [...this.amountByIdx];
    }
  }

  removeProduct(idx: number) {
    this.amountByIdx[idx].amount = Math.max(0, this.amountByIdx[idx].amount - 1);
    this.amountByIdx[idx].orderError = undefined;
    if (!this.amountByIdx[idx].amount) {
      this.amountByIdx[idx].show = false;
    }
    this.amountByIdx = [...this.amountByIdx];
  }

  showConfirm() {
    if (!this.amountByIdx.map(p => p.amount).reduce((a, b) => a + b, 0)) {
      return;
    }

    for (let p of this.sellableProductList) {
      if (!this.amountByIdx[p.idx].amount) {
        continue;
      }

      if (this.sellableError(p, this.deliveryDate)) {
        return;
      }
    }

    this.confirmModal = true;
  }

  copyProduct(idx: number) {
    let amount = this.amountByIdx[idx];
    let product = this.sellableProductList[idx];
    this.sellableProductList.splice(idx + 1, 0, deepClone(product));
    this.amountByIdx.splice(idx + 1, 0, deepClone(amount));
    this.amountByIdx[idx].show = false;
    this.amountByIdx = [...this.amountByIdx];
    this.sellableProductList = this.sellableProductList.map((p, i) => ({ ...p, idx: i }));
  }
}
